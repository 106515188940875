var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Create Challenge")])]),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Target Name","error-messages":errors,"outlined":"","dense":"","placeholder":"Target Title"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"About The Target","type":"text","error-messages":errors,"outlined":"","dense":"","placeholder":"Target description"},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"start_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.categoryOptions,"error-messages":errors,"label":"Select a category","outlined":""},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"target_amount","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Target Amount","outlined":"","error-messages":errors,"dense":"","type":"number","placeholder":"Target Amount"},model:{value:(_vm.form.target_amount),callback:function ($$v) {_vm.$set(_vm.form, "target_amount", $$v)},expression:"form.target_amount"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"frequency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.savingOptions,"error-messages":errors,"label":"How often would you prefer to save ?","outlined":""},model:{value:(_vm.form.frequency),callback:function ($$v) {_vm.$set(_vm.form, "frequency", $$v)},expression:"form.frequency"}})]}}],null,true)}),_c('div',[_c('validation-provider',{attrs:{"name":"form.amount_per_saving","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.form.frequency)?_c('v-text-field',{attrs:{"error-messages":errors,"label":'Prefered Amount to save on a' + _vm.form.frequency + ' basis',"outlined":"","dense":"","type":"number","placeholder":"Prefered Amount"},model:{value:(_vm.form.amount_per_saving),callback:function ($$v) {_vm.$set(_vm.form, "amount_per_saving", $$v)},expression:"form.amount_per_saving"}}):_vm._e()]}}],null,true)}),_c('v-menu',{ref:"menu3",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"from.frequency_data.time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Prefered time to save","readonly":"","error-messages":errors,"outlined":""},model:{value:(_vm.form.frequency_data.time),callback:function ($$v) {_vm.$set(_vm.form.frequency_data, "time", $$v)},expression:"form.frequency_data.time"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[(_vm.menu3)?_c('v-time-picker',{attrs:{"full-width":""},model:{value:(_vm.form.frequency_data.time),callback:function ($$v) {_vm.$set(_vm.form.frequency_data, "time", $$v)},expression:"form.frequency_data.time"}}):_vm._e()],1),(_vm.form.frequency === 'weekly')?_c('v-select',{attrs:{"items":_vm.daysOfWeek,"label":"On which day of the week would you like to save ?","outlined":""},model:{value:(_vm.form.frequency_data.day_of_week),callback:function ($$v) {_vm.$set(_vm.form.frequency_data, "day_of_week", $$v)},expression:"form.frequency_data.day_of_week"}}):_vm._e(),(_vm.form.frequency === 'monthly')?_c('v-select',{attrs:{"items":_vm.daysOfMonth,"label":"On which day of the month would you like to save ?","outlined":""},model:{value:(_vm.form.frequency_data.day_of_month),callback:function ($$v) {_vm.$set(_vm.form.frequency_data, "day_of_month", $$v)},expression:"form.frequency_data.day_of_month"}}):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"When do you want to start saving?","hint":"MM/DD/YYYY format","aria-errormessage":_vm.errors,"readonly":"","outlined":""},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}})],1)],1),_c('v-col',[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"When do you want to withdraw ?","hint":"MM/DD/YYYY format","readonly":"","outlined":""},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}})],1)],1)],1)],1),_c('v-switch',{staticClass:"mb-10",attrs:{"label":"Lock this target. If locked you can only withdraw at the end of the challenge","color":"primary ","hide-details":""},model:{value:(_vm.form.locked),callback:function ($$v) {_vm.$set(_vm.form, "locked", $$v)},expression:"form.locked"}}),(_vm.form.target_amount)?_c('label',[_vm._v("Declarations"),_c('span',{attrs:{"color":"error"}},[_vm._v("(important)")])]):_vm._e(),(_vm.form.target_amount)?_c('hr'):_vm._e(),(_vm.form.target_amount)?_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":'I hereby agree that i will forfeit the interest accured on this Target savings if i fail to meet this target amount of ' +
                    _vm.form.target_amount +
                    ' by the set withdraw date'},model:{value:(_vm.terms.first),callback:function ($$v) {_vm.$set(_vm.terms, "first", $$v)},expression:"terms.first"}}):_vm._e(),(_vm.form.target_amount)?_c('v-checkbox',{staticClass:"mt-0",attrs:{"label":"I hereby agree that if i break this target before the withdraw data , i wil loose all the interest accured on this target and bear the 2.5% payment gateway charge for processing my diposits into this target"},model:{value:(_vm.terms.second),callback:function ($$v) {_vm.$set(_vm.terms, "second", $$v)},expression:"terms.second"}}):_vm._e(),_c('v-btn',{attrs:{"color":"primary","disabled":invalid},on:{"click":_vm.createChallenge}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":25,"color":"white"}}):_vm._e(),_c('span',[_vm._v("Submit")])],1),_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" Cancel ")])],1)]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","md":"6"}},[_c('v-snackbar',{attrs:{"multi-line":_vm.multiLine},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"red","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._l((_vm.errors),function(error,key){return _c('v-list-item',{key:key},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(key))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(error))])],1)],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }