<template>
  <div>
    <v-row>
      <v-col cols="12" md="8">
        <v-card>
          <v-card-title>
            <span class="headline">Create Challenge</span>
          </v-card-title>
          <v-card-text>
            <validation-observer ref="observer" v-slot="{ invalid }">
              <v-form>
                <validation-provider v-slot="{ errors }" name="name" rules="required">
                  <v-text-field
                    v-model="form.name"
                    label="Target Name"
                    :error-messages="errors"
                    outlined
                    dense
                    placeholder="Target Title"
                  ></v-text-field>
                </validation-provider>
                <validation-provider v-slot="{ errors }" name="description" rules="required">
                  <v-textarea
                    v-model="form.description"
                    label="About The Target"
                    type="text"
                    :error-messages="errors"
                    outlined
                    dense
                    placeholder="Target description"
                  ></v-textarea>
                </validation-provider>

                <validation-provider v-slot="{ errors }" name="start_date" rules="required">
                  <v-select
                    :items="categoryOptions"
                    v-model="form.category"
                    :error-messages="errors"
                    label="Select a category"
                    outlined
                  ></v-select>
                </validation-provider>

                <validation-provider v-slot="{ errors }" name="target_amount" rules="required">
                  <v-text-field
                    v-model="form.target_amount"
                    label="Target Amount"
                    outlined
                    :error-messages="errors"
                    dense
                    type="number"
                    placeholder="Target Amount"
                  ></v-text-field>
                </validation-provider>

                <validation-provider v-slot="{ errors }" name="frequency" rules="required">
                  <v-select
                    v-model="form.frequency"
                    :items="savingOptions"
                    :error-messages="errors"
                    label="How often would you prefer to save ?"
                    outlined
                  ></v-select>
                </validation-provider>

                <div>
                  <validation-provider v-slot="{ errors }" name="form.amount_per_saving" rules="required">
                    <v-text-field
                      v-if="form.frequency"
                      v-model="form.amount_per_saving"
                      :error-messages="errors"
                      :label="'Prefered Amount to save on a' + form.frequency + ' basis'"
                      outlined
                      dense
                      type="number"
                      placeholder="Prefered Amount"
                    ></v-text-field>
                  </validation-provider>
                  <v-menu
                    ref="menu3"
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider v-slot="{ errors }" name="from.frequency_data.time" rules="required">
                        <v-text-field
                          v-model="form.frequency_data.time"
                          label="Prefered time to save"
                          readonly
                          v-bind="attrs"
                          :error-messages="errors"
                          outlined
                          v-on="on"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-time-picker v-if="menu3" v-model="form.frequency_data.time" full-width></v-time-picker>
                  </v-menu>
                  <v-select
                    v-if="form.frequency === 'weekly'"
                    v-model="form.frequency_data.day_of_week"
                    :items="daysOfWeek"
                    label="On which day of the week would you like to save ?"
                    outlined
                  ></v-select>
                  <v-select
                    v-if="form.frequency === 'monthly'"
                    v-model="form.frequency_data.day_of_month"
                    :items="daysOfMonth"
                    label="On which day of the month would you like to save ?"
                    outlined
                  ></v-select>
                  <v-row justify="center">
                    <v-col>
                      <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="form.start_date"
                            label="When do you want to start saving?"
                            hint="MM/DD/YYYY format"
                            :aria-errormessage="errors"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="form.start_date" no-title @input="menu1 = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-menu
                        ref="menu2"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="form.end_date"
                            label="When do you want to withdraw ?"
                            hint="MM/DD/YYYY format"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-model="form.end_date" no-title @input="menu2 = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </div>
                <v-switch
                  v-model="form.locked"
                  label="Lock this target. If locked you can only withdraw at the end of the challenge"
                  color="primary "
                  class="mb-10"
                  hide-details
                ></v-switch>
                <label v-if="form.target_amount">Declarations<span color="error">(important)</span></label>
                <hr v-if="form.target_amount" />
                <v-checkbox
                  v-if="form.target_amount"
                  v-model="terms.first"
                  :label="
                    'I hereby agree that i will forfeit the interest accured on this Target savings if i fail to meet this target amount of ' +
                      form.target_amount +
                      ' by the set withdraw date'
                  "
                  class="mt-0"
                ></v-checkbox>
                <v-checkbox
                  v-if="form.target_amount"
                  v-model="terms.second"
                  label="I hereby agree that if i break this target before the withdraw data , i wil loose all the interest accured on this target and bear the 2.5% payment gateway charge for processing my diposits into this target"
                  class="mt-0"
                ></v-checkbox>

                <v-btn color="primary" :disabled="invalid" @click="createChallenge">
                  <v-progress-circular v-if="loading" indeterminate :size="25" color="white"></v-progress-circular>
                  <span>Submit</span>
                </v-btn>

                <v-btn outlined class="mx-2" @click="$router.go(-1)">
                  Cancel
                </v-btn>
              </v-form>
            </validation-observer>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" class="mx-auto">
        <v-snackbar v-model="snackbar" :multi-line="multiLine">
          <template >
            <v-list-item v-for="(error, key) in errors" :key="key">
              <v-list-item-content>
                <v-list-item-title>{{ key }}</v-list-item-title>
                <v-list-item-subtitle>{{ error }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { required, digits, email, max, regex } from 'vee-validate/dist/rules'

// eslint-disable-next-line object-curly-newline
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
  ...email,
  message: 'Email must be valid',
})
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      snackbar: false,
      time: null,
      menu1: false,
      menu2: false,
      menu3: false,
      loading: false,
      terms: {
        first: false,
        second: false,
      },
      errors: {},
      form: {
        name: '',
        target_amount: '',
        amount_per_saving: '',
        description: '',
        frequency: '',
        start_date: '',
        end_date: '',
        visibility: 'private',
        type: 'individual',
        category: '',
        locked: false,
        frequency_data: { time: '00:01', day_of_week: '1', day_of_month: '1' },
      },
      savingOptions: [
        { text: 'Monthly', value: 'monthly' },
        { text: 'Weekly', value: 'weekly' },
        { text: 'Daily', value: 'daily' },
        { text: 'Anytime', value: 'anytime' },
      ],
      categoryOptions: [
        { text: 'Rent/Accomodation', value: 'rent/accomondation' },
        { text: 'Vacation/Travel', value: 'vacation/travel' },
        { text: 'Car/Vehicle', value: 'car/vehicle' },
        { text: 'Education', value: 'education' },
        { text: 'Health', value: 'health' },
        { text: 'Fees or Debit', value: 'Fees or Debit' },
        { text: 'Starting/Growing Businesses', value: 'starting/growing businesses' },
        { text: 'Events eg weddings, parties, etc', value: 'events eg weddings, parties, etc' },
        { text: 'Birthday', value: 'birthday' },
        { text: 'Investment', value: 'investment' },
        { text: 'Unforeseen circumstances', value: 'unforeseen circumstances' },
        { text: 'Gadgets', value: 'gadgets' },
        { text: 'No reason', value: 'no reason' },
        { text: 'Other', value: 'other' },
      ],
      daysOfWeek: [
        { text: 'Monday', value: 1 },
        { text: 'Tuesday', value: 2 },
        { text: 'Wednesday', value: 3 },
        { text: 'Thursday', value: 4 },
        { text: 'Friday', value: 5 },
        { text: 'Saturday', value: 6 },
        { text: 'Sunday', value: 7 },
      ],
      daysOfMonth: this.getDaysOfMonth(),
    }
  },
  methods: {
    // function returns day of month
    getDaysOfMonth() {
      const days = []
      for (let i = 1; i <= 29; i += 1) {
        let dispalyText = i
        if (i === 1) {
          dispalyText = '1st'
        }
        if (i === 2) {
          dispalyText = '2nd'
        }
        if (i === 3) {
          dispalyText = '3rd'
        }
        if (i >= 4) {
          dispalyText = `${i}th`
        }

        days.push({ text: dispalyText, value: i })
      }

      return days
    },
    createChallenge() {
      this.loading = true
      this.$http
        .post('/challenges', this.form)
        .then(response => {
          console.log(response.data)
          this.$router.push('/challenges')
        })
        .catch(errors => {
          this.errors = errors.response.data.errors
          this.snackbar = true
          console.error(errors)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style></style>
